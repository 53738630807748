<template>
  <div>
    <TButton
      content="Create a new post"
      to="posts/new"
      icon="cil-plus"
      class="mb-3"
    />
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="helper.posts"
      resource="posts"
      @click-clear-filter="clearFilter"
      enterable
      reloadable
      @click-reload="filterChange"
      class="table-custom"
      deletable
    >
      <template #title="{ item }">
        <td>
          <TMessageText
            :value="item.title"
            class="h5 mb-1"
            :messageOptions="{ truncate: 2 }"
          />
          <TMessage
            :content="item.description"
            noTranslate
            description
            italic
            :truncate="2"
          />
        </td>
      </template>
      <template #creator="{ item }">
        <td>
          <TMessageText v-if="item.creator" :value="item.creator.name" />
          <TMessageText v-else :value="item.creator_id" />
        </td>
      </template>
      <template #categories="{ item }">
        <td>
          <CBadge
            color="primary"
            v-for="cat in item.categories"
            :key="cat.id"
            class="mr-1"
          >
            {{ cat.name }}
          </CBadge>
        </td>
      </template>
      <template #publish="{ item }">
        <td>
          <CBadge :color="item.publish ? 'success' : 'danger'">
            <TMessage :content="item.publish ? 'Publish' : 'Draft'" />
          </CBadge>
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" />
        </td>
      </template>
      <template #categories-filter>
        <SSelectPostCategory
          :value.sync="filter.categories"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #creator-filter>
        <SSelectCustomer
          :value.sync="filter.creator_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #title-filter>
        <TInputText
          :value.sync="filter.title"
          @update:value="filterChange"
          placeholder="Title"
        />
      </template>
      <template #publish-filter>
        <SSelectStatus
          :value.sync="publish"
          :options="[
            {
              id: '',
              name: $t('All'),
            },
            {
              id: '1',
              name: $t('Publish'),
            },
            {
              id: '0',
              name: $t('Draft'),
            },
          ]"
          @change="filterChange"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px", _classes: "" },
        {
          key: "title",
          label: "Support post",
          _style: "width: 350px; min-width: 350px",
        },

        {
          key: "categories",
          label: "Category",
          _classes: "text-nowrap",
          _style: "width: 220px; min-width: 220px",
        },

        {
          key: "creator",
          label: "Creator",
          _classes: "",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "publish",
          label: "Status",
          _classes: "text-nowrap",
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "created_at",
          label: "Created at",
          _style: "width: 220px; min-width: 220px",
        },
      ],
      filter: {},
      publish: "",
    };
  },
  created() {
    this.$store.dispatch("helper.posts.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "helper.posts.list",
      detail: "helper.posts.detail",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy({
        ...filter,
        [`filter[publish]`]: this.publish ? this.publish : "",
      });
      this.$store.dispatch("helper.posts.apply-query", filterFields);
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>

<style scoped>
.table-custom >>> .table-responsive {
  min-height: 400px;
}
</style>
